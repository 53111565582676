import { useEffect, useMemo } from 'react';
import { addHours, isPast } from 'date-fns';
import { useQuery } from 'react-apollo';

import { useSessionData } from '@confluence/session-data';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled, isErrorMarkedAsHandled } from '@confluence/graphql';
import { ConfluenceEdition } from '@confluence/change-edition';
import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { MENTION_REMINDERS_ONBOARDING_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { Cohorts, COHORT_KEY } from '@confluence/onboarding-helpers/entry-points/constants/cohorts';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { TrialDetailsQuery } from '../queries/TrialDetailsQuery.graphql';
import type { TrialDetailsQuery as TrialDetailsQueryType } from '../queries/__types__/TrialDetailsQuery';

import { usePageMentionedAccountIds } from './usePageMentionedAccountIds';
import { usePageAudience } from './usePageAudience';

export const useMentionRemindersEligible = (contentId) => {
	const { edition } = useSessionData();

	const [experiment, fireExposure] = UNSAFE_noExposureExp('cc_onboarding_mention_reminders');

	const cohort = experiment.get<Cohorts>(COHORT_KEY, Cohorts.NOT_ENROLLED) as Cohorts;

	const { mentionedAccountIds, lastModifiedDate, loadMentionedAccountIds } =
		usePageMentionedAccountIds(contentId);

	const validConfigConditions =
		cohort !== Cohorts.NOT_ENROLLED && edition === ConfluenceEdition.PREMIUM;

	const {
		data: trialDetailsData,
		error: trialDetailsError,
		loading: trialDetailsLoading,
	} = useQuery<TrialDetailsQueryType>(TrialDetailsQuery, {
		skip: !validConfigConditions,
	});

	if (isUnauthorizedError(trialDetailsError) && !isErrorMarkedAsHandled(trialDetailsError)) {
		markErrorAsHandled(trialDetailsError);
	}

	let isTrialInstance = true;
	if (!trialDetailsLoading && !trialDetailsData?.license?.trialEndDate) {
		isTrialInstance = false;
	}

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		Object.values(MENTION_REMINDERS_ONBOARDING_STATE_KEYS),
		!validConfigConditions || isTrialInstance,
	);

	const { wasAnalyticsBylineNudgeSeen } = deserializeState(onboardingStateData);
	const skip =
		!validConfigConditions ||
		onboardingStateLoading ||
		wasAnalyticsBylineNudgeSeen ||
		isTrialInstance;

	useEffect(() => {
		if (!skip) {
			// re-using cached ADF data
			loadMentionedAccountIds();
		}
	}, [loadMentionedAccountIds, skip]);

	// Step 1: Determine if they have mentions on the page
	const containsMentions = !!mentionedAccountIds?.length;

	// Step 2: The page must have been modified at least four hours ago to prevent users from being reminding too early
	const isModifiedOverFourHoursAgo =
		(lastModifiedDate && isPast(addHours(new Date(lastModifiedDate), 4))) || false;

	// Step 3: Run query to determine if at least one mentioned user hasn't viewed the page yet
	// Note: loadMentions is a lazy query and doesn't need to be skipped
	// the skip condition is for usePageViews
	const { mentionsForReminders, loadMentions, mentionedNotViewedCount } = usePageAudience(
		contentId,
		{
			skip,
			mentionsNotViewedCountLimit: 90,
			mentionsViewedCountLimit: 90,
			viewersCountLimit: 90,
		},
	);

	useEffect(() => {
		if (!skip && containsMentions && isModifiedOverFourHoursAgo) {
			loadMentions();
		}
	}, [containsMentions, isModifiedOverFourHoursAgo, loadMentions, skip]);

	// filters out deactivated or unlicensed users for first exposure
	const activeMentionsForReminders = useMemo(
		() =>
			mentionsForReminders.filter(
				(user) => !user.name?.includes('(Deactivated)') && !user.name?.includes('(Unlicensed)'),
			) ?? [],
		[mentionsForReminders],
	);

	const pageEligibleForExperiment = Boolean(
		containsMentions &&
			isModifiedOverFourHoursAgo &&
			mentionedNotViewedCount > 0 &&
			activeMentionsForReminders.length,
	);

	if (pageEligibleForExperiment) {
		fireExposure();
	}

	if (!validConfigConditions || isTrialInstance || onboardingStateError) {
		return {
			isMentionRemindersEligible: false,
		};
	}

	// pageEligibleForExperiment should only be true for the initial exposure (for analytics byline nudge)
	// wasAnalyticsBylineNudgeSeen is then used to keep track of future experiment eligibility
	return {
		isMentionRemindersEligible: Boolean(
			cohort === Cohorts.EXPERIMENT && (pageEligibleForExperiment || wasAnalyticsBylineNudgeSeen),
		),
	};
};
